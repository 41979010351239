import Vue from 'vue'

//Import Froala Editor
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/froala_editor.min.js';
import 'froala-editor/js/froala_editor.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
// import 'froala-editor/js/third_party/font_awesome.min';
// import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
//plugins
import 'froala-editor/js/languages/fa.js';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';

import '@/froala_kg'

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)