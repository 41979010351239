import Vue from 'vue';

const that = Vue.prototype

that.state = Vue.observable({
    token: localStorage.token || false,
    loading: true,
    gotten: false,
    refresh: 0,
    userInfo: {
        firstName: 'مهمان',
        lastName: '',
        role: '',
        rolePersian: '',
        privileges: []
    },
    exchangeIsAuto : true,
    requestCount: 0,
    menuItem:[],
    viewedMenus: localStorage.route ? JSON.parse(localStorage.route) : [],
})


// const func1 = {
//     ali: (number) => {
//         console.log('fsdgfdsagfs', number)
//     },
// }
